
import DynamicImage from '~/components/elements/DynamicImage';
import VideoIcon from '~/components/elements/VideoIcon';
export default {
  name: 'BannerGridItem',
  components: {VideoIcon, DynamicImage},
  props: {
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    youtube: {
      type: String,
      default: '',
    },
    ratio: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
  computed: {
    bannerStyle() {
      return {
        '--banner-radius': this.borderRadius,
      }
    },
  },
}


import CustomLink from '~/components/elements/CustomLink';
import BannerGridItem from '~/components/elements/BannerGridItem';
export default {
  name: 'BannerGridItemWrapper',
  components: {BannerGridItem, CustomLink},
  props: {
    item: {
      type: Object,
      required: true,
    },
    ratio: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
}


import CustomImage from '~/components/elements/CustomImage';
export default {
  name: 'DynamicImage',
  components: {CustomImage},
  props: {
    image: {
      type: Object,
      required: true,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    media: {
      type: [Array, null],
      default: null,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  data() {
    const defaultRatio = {
      mobile: '97.78%',
      tablet: '27.1%',
      pc: '27.1%',
    }

    return {
      imageRatio: {
        ...defaultRatio,
        ...this.ratio,
      },
    }
  },
  computed: {
    currentRatio() {
      let ratio = '0%'

      switch (this.$mq) {
        case 'mobileXs':
          ratio = this.imageRatio.mobile
          break;
        case 'tablet':
        case 'mobile':
          ratio = this.imageRatio.tablet
          break;
        default:
          ratio = this.imageRatio.pc
      }

      return ratio
    },
    alt() {
      return this.image?.caption || ''
    },
  },
}


import CoolLightBox from 'vue-cool-lightbox';
import GridWrapper from '~/components/elements/GridWrapper';
import BannerGridItemWrapper from '~/components/elements/BannerGridItemWrapper';
import {generateYoutubeVideoSourcePath} from '~/plugins/helpers/file';

export default {
  name: 'BannerGrid',
  components: {BannerGridItemWrapper, GridWrapper, CoolLightBox},
  props: {
    title: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      required: true,
    },
    ratio: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
  data() {
    return {
      imageOpenIndex: null,
    }
  },
  computed: {
    galleryItems() {
      const slides = []
      this.images.forEach(item => {
        if (item?.youtube) {
          slides.push({ src: generateYoutubeVideoSourcePath(item.youtube), youtube: item.youtube  })
        } else if (item?.image?.url?.large && !item?.url) {
          slides.push({ src: item.image.url.large })
        }
      })

      return slides
    },

  },
  methods: {
    onClickBanner(item) {
      const { youtube, image } = item

      let id = -1

      if (!youtube) {
        id = this.getCurrentGalleryId(image?.url?.large)
      } else {
        id = this.getCurrentYoutubeGalleryId(youtube)
      }

      if (id !== -1) {
        this.imageOpenIndex = id
      }
    },
    getCurrentYoutubeGalleryId(youtube) {
      return this.galleryItems.findIndex(item => item.youtube === youtube)
    },
    getCurrentGalleryId(src) {
      return this.galleryItems.findIndex(item => item.src === src)
    },
  },

}

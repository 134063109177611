
export default {
  name: 'GridWrapper',
  props: {
    title: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bannerRow() {
      return {
        '--banner-row-pc': `${this.row?.pc || 4}`,
        '--banner-row-tablet': `${this.row?.tablet || 3}`,
        '--banner-row-tablet-sm': `${this.row?.tabletSm || 2}`,
        '--banner-row-mobile': `${this.row?.mobile || 1}`,

        '--banner-column-pc': this.column?.pc,
        '--banner-column-tablet': this.column?.tablet,
        '--banner-column-tablet-sm': this.column?.tabletSm,
        '--banner-column-mobile': this.column?.mobile,
      }
    },
  },
}
